<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo /><h2 class="brand-text text-primary ml-1">
        Smart ADM
      </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="sideImg"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col  sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title  title-tag="h2" class="font-weight-bold mb-1">
            Bem Vindo ao Smart ADM! 👋
          </b-card-title>

          <b-alert variant="danger" if="errors" show>
            <div v-for="(error, index) in errors" :key="index" class="alert-body">
              {{ index | passwordFilter }} {{ error[0] }}
            </div>
          </b-alert>

          <b-alert v-if="errors_user" variant="danger" show >
            <div class="alert-body">
              Há mais de um usuário utilizando a mesma conta!!
            </div>

            <div class="alert-body">
              Ao fazer o Login, os demais usuários serão desconectados!
            </div>
          </b-alert>

          <!-- form Login -->
          <template v-if="!usuarioVerificado">
            <validation-observer ref="loginValidation">
              <b-form class="auth-login-form mt-2" @submit.prevent>

                <!-- email -->
                <b-form-group  label="Email" label-for="login-email">
                  <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                  >
                    <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false:null"
                        name="login-email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Senha</label>
                    <b-link :to="{name:'forgot-password'}">
                      <small>Esqueceu a sua senha?</small>
                    </b-link>
                  </div>
                  <validation-provider
                      #default="{ errors }"
                      name="Senha"
                      rules="required"
                  >
                    <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                          id="login-password"
                          v-model="senha"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                      id="remember-me"
                      v-model="status"
                      name="checkbox-1"
                  >
                    Lembrar Me
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                    type="submit"
                    variant="primary"
                    block
                    @click.stop.prevent="validationForm"
                    :disabled="submit"
                >
                  Entrar
                </b-button>
              </b-form>
            </validation-observer>
          </template>
          <!-- form Login -->

          <!-- form Codigo -->
            <template v-else>
              <validation-observer ref="codigoLogin">
                <b-form
                    class="auth-forgot-password-form mt-2"
                    @submit.prevent="verificaCodigo"
                >
                  <!-- email -->
                  <b-form-group
                      label="Código"
                      label-for="codigo"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Código"
                        rules="required|min:6|max:6"
                    >
                      <b-form-input
                          id="codigo"
                          v-model.trim="codigo"
                          :state="errors.length > 0 ? false:null"
                          name="Código"
                          :disabled="submit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- submit button -->
                  <b-button  variant="primary" block type="submit" :disabled="submit">
                    <b-spinner small v-if="submit" />
                    <span v-if="submit">Aguarde...</span>
                    <span v-else> Verificar Código</span>
                  </b-button>
                </b-form>
              </validation-observer>
            </template>
          <!-- fim Codigo -->

        </b-col>
      </b-col >
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import Cookie from '../../services/cookie'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    Cookie,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      senha: 'admin',
      userEmail: 'rodrigo.tozzo@gmail.com',
      usuarioVerificado: false,
      codigo: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      submit: false,
      errors: [],

    }
  },
  computed: {
    ...mapState({
      errors_user: state => state.user.errors_user,
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'senha' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrlBackground() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },

  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {

          this.submit = true

          const payload = {
            email: this.userEmail,
            password: this.senha,
          }

          this.errors = []

          this.$http.post('adm/login', payload).then(res => {

            store.commit('user/STORE_USER', res.data)
            store.commit('user/STORE_USER_ERROR_LOGIN', false)

            if(res.data.data.mfa) {
              this.usuarioVerificado = true
            }else{
              this.$router.push({ name: 'mfa' })
            }

          }).catch(error => {

            Cookie.deleteToken()

            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }

            if (error.response.status === 401) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.message,
                  icon: 'InfoIcon',
                  variant: 'danger',
                },
              })
            }
          }).finally( () => {
            this.submit = false
          })
        }
      })
    },
    verificaCodigo() {

      this.$refs.codigoLogin.validate().then( (success) => {
        if(success) {

          this.submit = true
          this.errors = []

          const payload = {
            email: this.userEmail,
            password: this.senha,
            codigo: this.codigo,
          }

          this.$http.post('adm/mfa/login', payload)
            .then((res) => {

              Cookie.setToken(res.data.access_token, res.data.expires_in)
              store.commit('user/STORE_USER', res.data)
              store.commit('user/STORE_USER_ERROR_LOGIN', false)

              this.getErrors = false;
              this.$refs.codigoLogin.reset();

              this.$router.push({ name: 'home' })
            })
            .catch((error) => {
              Cookie.deleteToken()

              if (error.response.status === 422) {
                this.errors = error.response.data.errors
              }

              if (error.response.status === 401) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.message,
                    icon: 'InfoIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .finally(() => {
              this.submit = false;
          });
        }
      })

    }

  },
  filters: {
    passwordFilter(text) {
      if (text == 'password') {
        return 'Senha'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';

</style>
